.CalendarFilter{
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.calendar_filter_text{
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #696974;
    font-family: 'Roboto', sans-serif;
}

.calendar_filter_text_selected{
    letter-spacing: 0.2px;
    font-size: 14px;
    color: #171725;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    padding-right: 4px;
    box-sizing: border-box;
}

.caledar_filter_icon{
    width: 10px;
    cursor: pointer;
    transition: all .25s ease-in;
}

.calendar_filter_list{
    padding: 10px 0px 10px 0px;
    position: absolute;
    top: 100%;
    right: 0;
    border-radius: 10px;
    background: #fff;
    overflow: hidden;
    transition: all .25s ease-in;
}

.calendar_filter_list_text{
    margin-bottom: 5px;
    padding-left: 15px;
    box-sizing: border-box;
    height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #171725;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
}

.calendar_filter_list_text:hover{
    background: rgb(235, 235, 235);
}
