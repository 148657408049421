@import '../../global.scss';

.Header{
    width: 100%;
    height: 70px;
    padding: 5px 20px 5px 25px;
    box-sizing: border-box;
    background: $bg1;
    box-shadow: inset 0px -1px 0px #e2e2ea;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header_sx{
    overflow: hidden;
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
}

.header_dx{
    position: relative;
    display: flex;
    align-items: center;
}

.header_sx_open_menu{
    margin-right: 15px;
    cursor: pointer;
}

.header_sx_title{
    margin: 0%;
    font-size: 18px;
    color: $fontColor1;
    font-weight: 600;
}

.header_dx_profile{
    width: 32px;
    height: 32px;
    background: #0062ff;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 50%;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 15px;
    font-family: 'Poppins', sans-serif;
}

.header_dx_profile_list_text{
    margin: 0%;
    padding: 4px 0px 4px 15px;
    cursor: pointer;
}

.header_dx_profile_list_text:hover {
    background: rgb(238, 237, 237);
}

.header_dx_profile_list{
    position: absolute;
    right: 0;
    top: 100%;
    border-radius: 8px;
    background: $bg1;
    padding: 10px 0px 10px 0px;
    box-sizing: border-box;
    transition: all .3s;
    overflow: hidden;
    z-index: 1;
}

@media screen and (max-width: 800px) {
    .Header{
        height: 60px;
    }
}