.Auth{
    width: 100vw;
    height: 100vh;
    background: #f5f7ff;
    position: relative;
}

.auth_container{
    width: max-content;
    height: max-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.auth_title{
    margin: 0%;
    width: 100vw;
    font-size: 46px;
    font-weight: 600;
    margin-bottom: 25px;
    text-align: center;
    text-overflow: clip;
}

.auth_input_text{
    display: block;
    width: 350px;
    height: 50px;
    border: none;
    outline: none;
    padding: 0px 15px 0px 15px;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    margin: auto;
    margin-top: 10px;
}

.auth_submit{
    font-family: 'Poppins', sans-serif;
    width: 350px;
    height: 60px;
    background: #3D80ED;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    margin: auto;
    margin-top: 10px;
    box-shadow: 0 10px #0f2e7e;
    transition: all 0.3s ease;
}

.auth_submit:hover{
    box-shadow: 0 5px #0f2e7e;
    border-radius: 10px;
}

@media screen and (max-width: 400px) {
    .auth_input_text{
        width: 95vw;
    }
    .auth_submit{
        width: 95vw;
    }
}