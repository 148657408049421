.SelectFilter{
    width: 180px;
    height: 30px;
    background-color: #fff;
    padding-left: 10px;
    border-radius: 5px;
    border: 1px solid #F1F1F5;
    display: flex;
    position: relative;
}

.select_filter_text{
    width: 155px;
    height: 100%;
    border-right: 1px solid #F1F1F5;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #696974;
    font-weight: 300;
    font-family: Roboto;
    cursor: pointer;
}

.select_filter_textfilter{
    font-weight: 500;
    width: 45%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.select_filter_rigth{
    flex: 100;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.select_filter_right_caret{
    display: block;
    width: 17px;
    margin: auto;
    transition: all .25s ease-in;
}

.select_filter_list{
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: max-content;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #F1F1F5;
    font-size: 15px;
    font-family: Roboto;
    z-index: 1;
    box-sizing: border-box;
    transition: all .25s ease-in;
    overflow: hidden;
}

.select_filter_list_text{
    padding: 5px 0px 5px 10px;
    cursor: pointer;
    color: #696974;
}

.select_filter_list_text:hover {
    background-color: rgb(236, 236, 236);
}