.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.app_remaining_page{
  width: 100%;
  flex: 1;
  display: flex;
}

#app-loader{
  position: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000000;
}

@media screen and (max-height: 568px) {
  .App {
    height: 568px;
  }
}

@media screen and (max-width: 800px) {
  .sidebar_header{
      height: 60px;
  }
}