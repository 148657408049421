.Modal2{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba($color: #ffffff, $alpha: 0.8);
    z-index: 100000;
    cursor: default;
}

.modal2_container{
    width: 450px;
    height: max-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background: #ebf0f8;
    border-radius: 20px;
    transition: all .25s;
}

.modal2_col{
    height: 100%;
    width: 200px;
    margin-bottom: 10px;
}

.modal2_container_header{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
}

.modal2_container_header_text{
    font-size: 14px;
    font-weight: 600;
    color: #4378DB;
}

.modal2_close_icon{
    cursor: pointer;
}

.modal2_container_body{
    margin-top: 30px;
    width: 100%;
    height: max-content;
}

.modal2_row{
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.modal2_contact_text{
    color: #4378DB;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 7px;
}

.modal2_task_text{
    color: #4378DB;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 2px;
}

.modal2_contact_input_text{
    width: 100%;
    height: 30px;
    padding: 0px 12px 0px 12px;
    box-sizing: border-box;
    border: none;
    outline: none;
    border-radius: 7px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.modal2_task_input_text{
    height: 34px;
    margin-bottom: 10px;
}

.modal2_contact_input_text::placeholder{
    color: black;
}

.modal2_contact_textarea{
    width: 100%;
    height: 70px;
    resize: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    border: none;
    border-radius: 7px;
    outline: none;
    padding: 12px;
    box-sizing: border-box;
}

.modal2_contact_textarea::placeholder{
    color: black;
}

.modal2_contact_footer{
    width: max-content;
    margin: auto;
    margin-top: 60px;
}

.modal2_contact_footer_2{
    width: 100%;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
}

.modal2_contact_error{
    margin-top: 5px;
    color: rgb(233, 44, 44);
    font-size: 14px;
    font-weight: 500;
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0.4; /* Firefox */
}

input[type=date]:invalid::-webkit-datetime-edit {
    color: rgba(0,0,0,0.4);
}

textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0.4; /* Firefox */
}

input[type="date"] {
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

@media only screen and (max-width: 500px) {
    .modal2_container{
        width: 80%;
    }
    .modal2_col{
        width: 100%;
    }
    .modal2_contact_footer{
        margin-top: 20px;
    }
    .modal2_container_body{
        margin-top: 15px;
    }
}

@keyframes showBG {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes hideBG {
    from {opacity: 1;}
    to {opacity: 0;}
}

@keyframes showModal {
    from {
        opacity: 0;
        transform: scale(0) translate(-50%, -50%);
    }
    to {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%);
    }
}

@keyframes hideModal {
    from {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%);
    }
    to {
        opacity: 0;
        transform: scale(0) translate(-50%, -50%);
    }
}

