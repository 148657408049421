.Calendar{
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.CalendarHeader{
    width: 100%;
    height: 72px;
    padding: 0px 30px 0px 30px;
    box-sizing: border-box;
    border-bottom: 1.5px solid #E2E2EA;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.calendar_header_dx{
    display: flex;
    align-items: center;
}

.calendar_body{
    width: 100%;
    flex: 1;
    display: flex;
}

.calendar_body_title{
    width: 53px;
    height: 100%;
    border-right: 1.5px solid #E2E2EA;
    display: flex;
    align-items: center;
}

.calendar_body_title_text{
    transform: rotate(90deg);
    color: #696974;
    letter-spacing: 0.1px;
    font-weight: 500;
    font-size: 16px;
    margin: auto;
}

.calendar_header_sx_text{
    color: #171725;
    font-weight: 600;
    font-size: 18px;
}

.CalendarPage{
    width: 100%;
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.CalendarDay{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.calenda_page_header{
    height: 100px;
    height: 90px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.calendar_day_container{
    width: 65%;
    height: 65%;
    border-radius: 10px;
    margin: auto;
    display: flex;
    align-items: center;
    max-width: 60px;
}

.calendar_day_container_text{
    width: max-content;
    height: max-content;
    margin: auto;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 3px;
}

.calendar_header_dx_today{
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    margin: 0px 20px 0px 20px;
    letter-spacing: 0.2px;
    color: #696974;
}

.calendar_page_task_container{
    width: 100%;
    flex-grow: 100;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 1px;
    box-sizing: border-box;
}

.calendar_page_task_container::-webkit-scrollbar{
    width: 8px;
}

.calendar_page_task_container::-webkit-scrollbar-thumb{
    background: #D5D5DC;
    border-radius: 15px;
}