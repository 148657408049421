@import '../../global.scss';

.WindowBtn{
    display: flex;
    align-items: center;
    border-radius: $windowHeaderBorderRadius;
    background: #50b5ff;
    font-family: 'Poppins', sans-serif;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    height: $windowHeaderHeight;
    padding: 0px 15px 0px 15px;
    transition: all 0.25s ease-in;
}

.WindowBtn:hover{
    transition: all 0.25s ease-in;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}

.window_btn_icon{
    margin-right: 8px;
}