@import '../../global.scss';

.SideBar{
    width: 250px;
    height: 100vh;
    overflow: hidden;
    background-color: #fff;
    position: absolute;
    top: -70px;
    right: 0px;
}

.sidebar_header{
    width: 100%;
    height: 70px;
    box-shadow: inset 0px -1px 0px #e2e2ea;
    display: flex;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
}

.sidebar_btn_container{
    padding: 40px 0px 40px 0px;
    box-sizing: border-box;
}

.sidebar_header_close{
    cursor: pointer;
    width: 28px;
    margin-right: 10px;
    display: none;
}

.sidebar_header_logo2{
    display: none;
}

@media screen and (max-width: 1060px) {
    .SideBar{
        top: 0;
    }
    .sidebar_header_close{
        display: block;
    }
    .sidebar_header_logo2{
        display: block;
    }
    .sidebar_header_logo{
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .sidebar_header{
        height: 60px;
    }
}