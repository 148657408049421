.NotifyModal{
    width: 250px;
    height: 100px;
    background-color: #fff;
    box-sizing: border-box;
    transition: all .5s;
    overflow: hidden;
    border-radius: 10px;
}

.notify_modal_header_inbox{
    margin-top: 17px;
    width: 18px;
}

.notify_modal_header{
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: space-between;
}

.notify_modal_body{
    width: 100%;
}

.notify_modal_body_title{
    font-weight: 500;
    font-size: 14px;
    margin: 0%;
    margin-top: 4px;
}

.notify_modal_body_desc{
    font-weight: 400;
    font-size: 11px;
    margin: 0%;
    margin-top: 4px;
    color: rgb(129, 129, 129);
}