@import '../../global.scss';

.Modal{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba($color: #ffffff, $alpha: 0.6);
    top: 0;
    left: 0;
    user-select: none;
    cursor:default;
    z-index: 1000000;
}

.modal_container{
    width: 400px;
    height: max-content;
    padding: 20px;
    border-radius: $windowHeaderBorderRadius;
    border: 1px solid rgb(199, 199, 199);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $bg1;
}

.modal_container_text{
    margin: 0%;
    width: max-content;
    margin: auto;
}

.modal_container_header{
    width: 100%;
    height: 60px;
    position: relative;
    display: flex;
}

.modal_container_header_bottom_btn{
    align-self: flex-end;
    display: flex;
}

.modal_container_header_bottom_btn_space{
    width: 10px;
}

.modal_container_header_title{
    margin: 0 auto 0 auto;
    width: max-content;
}

.modal_container_header_close{
    width: 24px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
}

.modal_input{
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
}

@media screen and (max-width: 800px) {
    .modal_container{
        width: 80%;
    }
}