@import '../../global.scss';

.SideBarBtn{
    width: 100%;
    height: 52px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.sidebar_btn_icon{
    margin-left: 24px;
}

.sidebar_btn_text{
    margin: 0%;
    font-weight: 500;
    font-size: 14px;
    margin-left: 20px;
}

.sidebar_btn_leftline{
    background: $sideBarColor;
    width: 3px;
    height: 32px;
    border-radius: 0 3px 3px 0;
}