.Notifications{
    width: max-content;
    height: max-content;
    position: relative;
}

.notifications_svg{
    width: 30px;
    vertical-align: middle;
    cursor: pointer;
}

.notifications_daleggere{
    width: 9px;
    height: 9px;
    background: #fc5a5a;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    right: 3px;
}

.notification_container{
    width: max-content;
    height: max-content;
    position: absolute;
    overflow: hidden;
    top: 60px;
    right: 0;
    z-index: 400;
}

.notify_modal_header_close{
    width: 20px;
    cursor: pointer;
}

@media screen and (max-height: 710px) {
    .notification_container{
        max-height: 500px;
    }
}