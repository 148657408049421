@import '../../global.scss';

.SearchBar{
    width: 200px;
    height: 30px;
    border-radius: $windowHeaderBorderRadius;
    background: #f1f1f5;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.searchbar_icon{
    padding: 0px 6px 0px 6px;
}

.searchbar_input{
    height: 100%;
    width: 20px;
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    font-weight: 600;
    color: $fontColor1;
    font-family: 'Poppins', sans-serif;
}