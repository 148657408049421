.CalendarPageChanger{
    width: 75px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.calendar_page_changer_btn{
    width: 32px;
    height: 32px;
    border: 1px solid #E2E2EA;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.calendar_page_changer_btn_icon{
    margin: auto;
}