@import '../../global.scss';

.WindowItem{
    width: 330px;
    height: 250px;
    background: #ebf0f8;
    border-radius: 20px;
    margin: 20px 20px 0px 0px;
    padding: 35px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    opacity: 0;
    animation-name: fadeAnim;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-timing-function: ease-out;
    @keyframes fadeAnim {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

}

.WindowItem:hover .window_item_svg_icon{
    transform: scale(1.1);
}

.window_item_svg_icon{
    height: 100%;
    transition: all 0.25s ease-in;
    user-select:all;
}

.window_item_svg{
    width: 100%;
    height: 50%;
}

.window_item_row{
    width: 100%;
    height: 25%;
    display: flex;
    align-items: center;
}

.window_item_row_text{
    margin: 0%;
    width: 80%;
    font-size: 20px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #405db5;
}

.window_item_row_text2{
    margin: 0%;
    font-size: 18px;
    margin-top: 40px;
    color: #4378db;
}

.window_item_opt{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px;
    border-radius: 50%;
}

.window_item_opt:hover{
    background: #cedaec;
}

.window_item_opt_list{
    box-sizing: border-box;
    border-radius: 10px;
    background: $bg1;
    position: absolute;
    top: 60px;
    right: 20px;
    z-index: 200;
    padding: 10px 0 10px 0;
    overflow: hidden;
    transition: all .3s;
}

.window_item_opt_list_btn{
    margin: 0%;
    padding: 10px 0px 10px 20px;
    display: flex;
    align-items: center;
    margin: 5px 0px 5px 0px;
}

.window_item_opt_list_btn:hover{
    background: rgb(240, 240, 240);
}

@media screen and (max-width: 789px) {
    .WindowItem{
        margin: 20px 0px 0px 0px;
    }
}

@media screen and (max-width: 379px) {
    .WindowItem{
        width: 90vw;
    }
}