.CalendarTask{
    height: 79px;
    border-radius: 5px 20px 20px 5px;
    border: 1px solid #E2E2EA;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
}

.calendar_task_leftline{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 5px;
    height: 100%;
}

.calendar_task_desc{
    width: 60%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
}

.calendar_task_desk2{
    height: max-content;
    margin-left: 20px;
}

.calendar_task_desc_title{
    width: 100%;
    font-weight: 500;
    color: #171725;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.calendar_task_desk_filename{
    color: #696974;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.calendar_task_resize{
    width: 30px;
    position: absolute;
    height: 100%;
    right: -15px;
    top: 0;
    cursor: e-resize;
    opacity: 0;
}

.calendar_task_initials{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: white;
    border: none;
    outline: none;
    position: absolute;
    right: 20px;
    cursor: pointer;
    font-size: 11px;
}