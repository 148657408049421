.SelectBox{
    width: 100%;
    height: 34px;
    background-color: #fff;
    border-radius: 7px;
    box-sizing: border-box;
    display: flex;
    font-weight: 500;
    font-size: 13.5px;
    position: relative;
    margin-bottom: 10px;
}

.select_box_text{
    flex: 100;
    height: 100%;
    cursor: pointer;
    padding-left: 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.select_box_dx_caret {
    width: 30px;
    height: 100%;
    border-left: 1px solid #ebf0f8;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.select_box_dx_caret_img{
    margin-left: 4px;
    transition: all .25s ease-in;
}

.select_box_list {
    width: 100%;
    height: 250px;
    position: absolute;
    top: 35px;
    left: 0;
    background: #fff;
    border-radius: 7px;
    z-index: 1;
    flex-direction: column;
    overflow: hidden;
}

.select_box_list_header{
    width: 100%;
    height: 34px;
    border-bottom: 1px solid #ebf0f8;
    padding: 0px 12px 0px 12px;
    box-sizing: border-box;
    overflow: hidden;
}

.select_box_list_header_input{
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.select_box_list_body{
    width: 100%;
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    cursor: auto;
}

.select_box_list_body_item{
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 5px;
    box-sizing: border-box;
    font-weight: 400;
    margin-top: 5px;
}

.select_box_list_body_item_img{
    display: block;
    margin: auto;
    height: 25px;
}

.select_box_list_body_item_circle{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 10px;
    border: none;
    outline: none;
    color: #fff;
}

.modal2_task_color_container{
    display: flex;
    justify-content:space-between;
    height: max-content;
    width: 280px;
}

.select_box_list_body::-webkit-scrollbar {
    width: 6px;
}

.select_box_list_body::-webkit-scrollbar-thumb {
    background: #bcc5d3;
    border-radius: 15px;
}