@import '../global.scss';

.Window{
    height: 100%;
    width: 100%;
    background: $bg2;
    padding: 25px 0px 0px 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: absolute;
}

.window_header_title{
    margin: 0%;
    font-size: 22px;
    font-weight: 600;
    color: $fontColor1;
}

.window_header{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.window_header_sx{
    width: 600px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.window_header_dx{
    margin-right: 50px;
    display: flex;
    width: 250px;
    justify-content: space-between;
}

.window_header_dx_icon_container{
    width: 30px;
    height: 30px;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #F1F1F5;
}

.window_header_dx_icon{
    display: block;
    width: 20px;
    margin: auto;
}

.window_item{
    width: 100%;
    height: 1px;
    flex-grow: 100;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-content: flex-start;
}

.window_path{
    margin-top: 10px;
    width: 355px;
    height: 30px;
    display: flex;
    align-items: center;
}

.window_path_text{
    margin: 0%;
    max-width: 42%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 10s;
}

.window_path_parent{
    cursor: pointer;
}

.window_path_parent:hover{
    text-decoration: underline;
}

.window_item_warning{
    margin: 0%;
    width: 100%;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: rgb(180, 179, 179);
    text-align: center;
}

.window_calendar_container{
    width: 100%;
    height: 1px;
    flex-grow: 100;
    padding: 30px 50px 50px 0px;
    box-sizing: border-box;
}

@keyframes show {
    from {
        bottom: -100%;
        opacity: 0;
    }
    to {
        bottom: 0%;
        opacity: 1;
    }
}

@keyframes hide{
    from {
        bottom: 0%;
        opacity: 1;
    }
    to {
        bottom: 100%;
        opacity: 0;
    }
}

@media screen and (max-width: 700px) {
    .window_header{
        display: none;
    }
    .Window{
        padding: 0;
    }
}

@media screen and (max-width: 815px) {
    .window_item{
        justify-content: space-evenly;
    }
}

@media screen and (max-width: 360px) {
    .window_item{
        width: 100vw;
    }
}